<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="goBack" content="药品入库"> </el-page-header>
          <div>
            <el-button type="primary" size="small" @click="goBack" plain>取消</el-button>
            <el-button type="primary" size="small" @click="saveData('form')">保存</el-button>
          </div>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <el-form ref="form" :model="form" label-width="20px" :rules="rules" style="width: 98%; margin: 0 auto">
            <el-table :data="form.drugs" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
              <el-table-column prop="drugName" label="药品名称/商品名/条形码" width="260">
                <template slot-scope="scope">
                  <el-form-item label="" :prop="'drugs[' + scope.$index + '].drugOrgId'" :rules="rules.drugOrgId">
                    <el-select class="w-100 selectDrug" v-model="scope.row.drugOrgId" filterable remote clearable placeholder="药品名称/商品名/条形码" :remote-method="remoteMethod" :loading="isLoading" @change="handleSelect($event, scope.row)" size="small" @focus="currentIndex = scope.$index">
                      <el-option v-for="item in scope.row.options" :key="item.id" :label="`${item.mzDrug.drugName}${item.mzDrug.drugType == '' ? '' : item.mzDrug.drugType == 'T' ? '(统)' : item.mzDrug.drugType == 'X' ? '(选)' : '(精选)'}(${item.specification}/${item.specificationUnit}${item.productFactory ? ' - ' + item.productFactory : ''})`" :value="item.id"> </el-option>
                    </el-select>
                    <i class="warningTip el-icon-info" v-if="scope.row.showTip"> </i>
                    <span class="tipMsg">该药品不在您的药品库，请<span class="" @click="addDrug(scope.$index, scope.row)">新增药品</span> </span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="specification" label="药品规格">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-input v-model="scope.row.specification" size="small" placeholder="药品规格" disabled></el-input><span class="rightTip" style="right: 38px">{{ scope.row.specificationUnit }}</span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="productFactory" label="厂家">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-input v-model="scope.row.productFactory" size="small" placeholder="厂家" disabled></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="userPutInNumber" label=" 入库数量" width="160">
                <template slot-scope="scope">
                  <el-form-item label="" :prop="'drugs[' + scope.$index + '].userPutInNumber'" :rules="rules.userPutInNumber">
                    <el-input-number v-model="scope.row.userPutInNumber" size="small" controls-position="right" :precision="0" :step="1" :min="0" class="leftInput" @change="getPutInNumber($event, scope.row)"></el-input-number>
                    <span class="rightTip" style="right: 38px">
                      <span>{{ scope.row.specificationUnit }}</span>
                    </span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="putInPrice" label="进货价" width="160">
                <template slot-scope="scope">
                  <el-form-item label="" :prop="'drugs[' + scope.$index + '].putInPrice'" :rules="rules.putInPrice" v-if="orgInfo.settlementMode == 1">
                    <el-input-number v-model="scope.row.putInPrice" size="small" controls-position="right" :precision="2" :step="1" :min="0.01" class="leftInput" @blur="scope.row.tradingPrice = scope.row.putInPrice * rate"></el-input-number><span class="rightTip" style="right: 38px">元/{{ scope.row.specificationUnit }}</span>
                  </el-form-item>
                  <el-form-item label="" :prop="'drugs[' + scope.$index + '].putInPrice'" :rules="rules.putInPrice" v-else>
                    <el-input-number v-model="scope.row.putInPrice" size="small" controls-position="right" :precision="2" :step="1" :min="0.01" class="leftInput"></el-input-number><span class="rightTip" style="right: 38px">元/{{ scope.row.specificationUnit }}</span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="tradingPrice" label="售价" width="160">
                <template slot-scope="scope">
                  <el-form-item label="" :prop="'drugs[' + scope.$index + '].tradingPrice'" :rules="rules.tradingPrice">
                    <el-input-number v-model="scope.row.tradingPrice" size="small" controls-position="right" :precision="2" :step="1" :min="0.01" class="leftInput" :disabled="orgInfo.settlementMode == 1"></el-input-number>
                    <span class="rightTip" style="right: 38px">
                      <span v-if="scope.row.isSeparate">元/{{ scope.row.separateNumberUnit }}</span>
                      <span v-else>元/{{ scope.row.specificationUnit }}</span>
                    </span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="batchNumber" label="批号">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-input v-model="scope.row.batchNumber" size="small" placeholder="批号" clearable></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <!-- <el-table-column label="生产日期" width="170">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-date-picker v-model="scope.row.productionDate" align="right" type="date" placeholder="选择日期" :picker-options="pickerOptions0" style="width: 90%" size="small" @change="getDate1($event, scope.row)" value-format="yyyy-MM-dd"> </el-date-picker>
                  </el-form-item>
                </template>
              </el-table-column> -->
              <!-- <el-table-column prop="validYear" label="有效期" width="120">
                <template slot-scope="scope">
                  <el-form-item label="" :prop="'drugs[' + scope.$index + '].validYear'" :rules="rules.validYear">
                    <el-input-number v-model="scope.row.validYear" size="small" controls-position="right" :precision="0" :step="1" :min="1" class="leftInput" @change="getValidYean($event, scope.row)"></el-input-number>
                    <span class="rightTip" style="right: 38px">
                      <span>年</span>
                    </span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="validDate" label="到期时间" width="170">
                <template slot-scope="scope">
                  <el-form-item label="" :prop="'drugs[' + scope.$index + '].validDate'" :rules="rules.validDate">
                    <el-date-picker v-model="scope.row.validDate" align="right" type="date" placeholder="选择日期" :picker-options="pickerOptions2" style="width: 90%" size="small" value-format="yyyy-MM-dd" disabled> </el-date-picker>
                  </el-form-item>
                </template>
              </el-table-column> -->
              <el-table-column prop="datepicker" label="有效期" width="270">
                <template slot-scope="scope">
                  <el-form-item label="" :prop="'drugs[' + scope.$index + '].datepicker'" :rules="rules.datepicker">
                    <el-date-picker v-model="scope.row.datepicker" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" size="small" style="margin-right: 10px; width: 260px" clearable @change="setDate($event, scope.row)"> </el-date-picker>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" width="60px">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-button type="danger" size="mini" plain @click="handleDelete(scope.row, scope.$index)">
                      <i class="el-icon-delete" aria-hidden="true"></i>
                    </el-button>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
            <div style="padding: 0 10px">
              <span class="primary optionBtn" @click="handleAdd()">添加药品</span>
              <span class="warning optionBtn" @click="handleReset()">重置</span>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient'
import { Organization } from '@/components/HospitalDomain/Organization'
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    var organization = new Organization(this.TokenClient, this.Services.Authorization)
    return {
      OutpatientDomain: outpatient,
      OrganizationDomain: organization,
      tableData: [],
      addData: {},
      form: {
        drugs: [
          {
            showTip: false,
            queryString: null,
            drugOrgId: null,
            drugName: null,
            specification: null,
            specificationUnit: null,
            productFactory: null,
            batchNumber: null,
            isSeparate: false,
            separateNumber: null,
            separateNumberUnit: null,
            putInNumber: undefined,
            userPutInNumber: undefined,
            putInPrice: undefined,
            tradingPrice: undefined,
            copyPrice: undefined,
            batchNumber: null,
            validYear: undefined,
            validDate: null,
            datepicker: null,
            productionDate: null,
            isProductionDate: false,
            options: [],
          },
        ],
      },
      rules: {
        drugOrgId: [{ required: true, message: '请选择药品', trigger: 'change' }],
        userPutInNumber: [{ required: true, message: '请输入入库数量', trigger: 'blur' }],
        putInPrice: [{ required: true, message: '请输入进货价', trigger: 'blur' }],
        tradingPrice: [{ required: true, message: '请输入售价', trigger: 'blur' }],
        batchNumber: [{ required: true, message: '请输入批号', trigger: 'blur' }],
        validDate: [{ required: true, message: '请选择到期时间', trigger: 'change' }],
        validYear: [{ required: true, message: '请输入有效期', trigger: 'blur' }],
        datepicker: [{ required: true, message: '请选择有效期', trigger: 'change' }],
      },
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() > Date.now()
        // },
        shortcuts: [
          {
            text: '1年',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setFullYear(start.getFullYear() + 1)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '2年',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setFullYear(start.getFullYear() + 2)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '3年',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setFullYear(start.getFullYear() + 3)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '4年',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setFullYear(start.getFullYear() + 4)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '5年',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setFullYear(start.getFullYear() + 5)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() >= Date.now()
        },
      },
      pickerOptions2: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        },
      },
      isLoading: false,
      options: [],
      currentIndex: 0,
      orgInfo: {},
      rate: 2.5,
      isProductionDate: false,
    }
  },
  mounted() {
    let index = sessionStorage.getItem('addIndex')
    if (index != null) {
      let drugInfo = JSON.parse(sessionStorage.getItem('drugInfo'))
      if (drugInfo) {
        this.form = JSON.parse(sessionStorage.getItem('drugInfoForm'))
        sessionStorage.removeItem('addIndex')
        sessionStorage.removeItem('drugInfo')
        sessionStorage.removeItem('drugInfoForm')
        this.form.drugs[index].showTip = false
        this.form.drugs[index].drugOrgId = drugInfo.id
        this.form.drugs[index].drugName = drugInfo.mzDrug.drugName
        this.form.drugs[index].specification = drugInfo.mzDrug.specification
        this.form.drugs[index].specificationUnit = drugInfo.mzDrug.specificationUnit
        this.form.drugs[index].productFactory = drugInfo.mzDrug.productFactory
        this.form.drugs[index].isSeparate = drugInfo.isSeparate
        this.form.drugs[index].separateNumber = drugInfo.separateNumber
        this.form.drugs[index].separateNumberUnit = drugInfo.separateNumberUnit
        this.form.drugs[index].tradingPrice = drugInfo.tradingPrice / 100
        this.form.drugs[index].copyPrice = drugInfo.copyPrice / 100
      }
    }
    this.getOrgInfo()
  },
  methods: {
    getOrgInfo() {
      var _this = this
      _this.OrganizationDomain.GetOrgInfo(
        function (data) {
          _this.orgInfo = data.data.organization
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getPutInNumber(e, row) {
      if (row.isSeparate) {
        row.putInNumber = e * row.separateNumber
        return
      }
      row.putInNumber = e
    },
    getDate1(e, row) {
      console.log(e)
      if (e) {
        row.isProductionDate = true
      } else {
        row.isProductionDate = false
        row.validYear = undefined
        row.validDate = null
      }
    },
    getDate(e, row) {
      const date = new Date()
      let selYear = e.split('-')[0]
      let nowYear = date.getFullYear()
      row.validYear = selYear - nowYear
    },
    getDateStr(year, date) {
      let dataArry = date.split('-')
      let startYear = parseInt(dataArry[0])
      let endYear = startYear + year
      dataArry[0] = endYear
      return dataArry.join('-')
    },
    getValidYean(e, row) {
      if (e && e > 0) {
        if (row.productionDate) {
          row.validDate = this.getDateStr(e, row.productionDate)
        } else {
          const date = new Date()
          date.setFullYear(date.getFullYear() + e)
          row.validDate = date
        }
      } else {
        row.validDate = null
      }
    },
    setDate(e, row) {
      if (e) {
        if (e && e[0] && new Date(e[0]).getTime() >= Date.now()) {
          this.$message.error('开始日期不能在今天之后')
          row.datepicker = null
          return
        }
        row.productionDate = e[0] + ' 00:00:00'
        row.validDate = e[1] + ' 23:59:59'
      } else {
        row.productionDate = null
        row.validDate = null
      }
    },
    remoteMethod(queryString) {
      var _this = this
      _this.options = []
      _this.form.drugs[_this.currentIndex].options = []
      if (queryString !== '') {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
          _this.OutpatientDomain.DrugByOrgIn(
            queryString,
            function (res) {
              _this.form.drugs[_this.currentIndex].showTip = false
              _this.form.drugs[_this.currentIndex].queryString = null
              _this.options = res.data
              _this.options.forEach((item) => {
                if (item.tradingPrice == 0) {
                  _this.$set(item, 'isRate', true)
                } else {
                  _this.$set(item, 'isRate', false)
                }
              })
              _this.form.drugs[_this.currentIndex].options = res.data
            },
            function (error) {
              _this.form.drugs[_this.currentIndex].showTip = true
              _this.form.drugs[_this.currentIndex].queryString = queryString
              console.log('药品列表请求失败!请刷新重试:', error)
            }
          )
        }, 200)
      }
    },
    addDrug(index, item) {
      sessionStorage.setItem('drugInfoForm', JSON.stringify(this.form))
      sessionStorage.setItem('addIndex', index)
      this.$router.push({
        path: './AddDrug',
        query: {
          type: 'add',
          id: 0,
          drugName: item.queryString,
        },
      })
    },
    handleSelect(e, map) {
      let item = map.options.filter((i) => i.id == e)[0]
      if (item) {
        map.drugName = item.mzDrug.drugName
        map.specification = item.specification
        map.specificationUnit = item.specificationUnit
        map.productFactory = item.productFactory
        map.tradingPrice = item.tradingPrice ? item.tradingPrice : undefined
        map.copyPrice = item.tradingPrice
        map.isSeparate = item.isSeparate
        map.separateNumber = item.separateNumber
        map.separateNumberUnit = item.separateNumberUnit
      }
      // map.drugOrgId = e.id
    },
    saveData(formName) {
      var _this = this

      this.$refs[formName].validate((valid) => {
        if (valid) {
          for (let index = 0; index < this.form.drugs.length; index++) {
            let item = this.form.drugs[index]
            if (item.copyPrice != item.tradingPrice) {
              _this
                .$confirm('将同时修改所有批次药品售价，继续操作', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning',
                })
                .then(() => {
                  this.AddDrugOrgIn()
                  return
                })
                .catch(() => {
                  return
                })
            } else if (!item.isSeparate && item.putInPrice > item.tradingPrice) {
              _this
                .$confirm('售价小于进货价，继续操作', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning',
                })
                .then(() => {
                  this.AddDrugOrgIn()
                  return
                })
                .catch(() => {
                  return
                })
            } else {
              this.AddDrugOrgIn()
              return
            }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    AddDrugOrgIn() {
      var _this = this
      _this.OutpatientDomain.AddDrugOrgIn(
        _this.form.drugs,
        function (data) {
          _this.$message({
            message: '添加成功',
            type: 'success',
          })
          _this.$router.go(-1)
        },
        function (err) {
          _this.$message({
            message: '添加失败',
            type: 'error',
          })
        }
      )
    },
    handleReset() {
      let row = {
        showTip: false,
        queryString: null,
        drugOrgId: null,
        drugName: null,
        specification: null,
        specificationUnit: null,
        productFactory: null,
        batchNumber: null,
        isSeparate: false,
        separateNumber: null,
        separateNumberUnit: null,
        putInNumber: undefined,
        userPutInNumber: undefined,
        putInPrice: undefined,
        tradingPrice: undefined,
        copyPrice: undefined,
        batchNumber: null,
        validYear: undefined,
        validDate: null,
        datepicker: null,
        productionDate: null,
        isProductionDate: false,
        options: [],
      }
      this.form.drugs = []
      this.form.drugs.push(row)
    },
    handleAdd() {
      let row = {
        showTip: false,
        queryString: null,
        drugOrgId: null,
        drugName: null,
        specification: null,
        specificationUnit: null,
        productFactory: null,
        batchNumber: null,
        isSeparate: false,
        separateNumber: null,
        separateNumberUnit: null,
        putInNumber: undefined,
        userPutInNumber: undefined,
        putInPrice: undefined,
        tradingPrice: undefined,
        copyPrice: undefined,
        batchNumber: null,
        validYear: undefined,
        validDate: null,
        datepicker: null,
        productionDate: null,
        isProductionDate: false,
        options: [],
      }
      this.options = []
      this.form.drugs.push(row)
    },
    handleDelete(row, index) {
      if (this.form.drugs.length == 1) {
        this.$message({
          message: '请至少保留一行',
          type: 'warning',
        })
        return
      }
      this.$message({
        message: '删除成功',
        type: 'success',
      })
      this.form.drugs.splice(index, 1)
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 15px;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }

    // padding-left: 20px;
    .title {
      background: #f4f5f7;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      margin-bottom: 20px;
    }

    ::v-deep .el-table__row > td {
      /* 去除表格线 */
      border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__body-wrapper .el-table__cell {
      padding: 2px 0 !important;
    }

    // ::v-deep .el-form-item{
    //   margin-bottom: 0 !important;
    // }
    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }

    .el-form-item .el-form-item {
      margin-bottom: 20px;
    }

    .leftInput {
      width: 100%;
      position: relative;
    }

    .rightTip {
      position: absolute;

      top: 0;
      z-index: 2;
      color: #999999;
    }

    ::v-deep .el-input-number .el-input__inner {
      text-align: left;
    }

    .selectDrug {
      position: relative;
    }

    .warningTip {
      color: red;
      position: absolute;
      top: 15px;
      right: 5px;
    }

    .tipMsg {
      position: absolute;
      top: 35px;
      left: 5px;
      background-color: rgba(0, 0, 0, 1);
      z-index: 9999;
      margin-top: -5px;
      padding: 0 5px;
      display: none;
      height: 25px;
      line-height: 25px;
      color: #f8f8f8;

      span {
        color: red;
      }

      span:hover {
        cursor: pointer;
      }
    }

    .warningTip:hover + .tipMsg {
      display: block;
    }

    .tipMsg:hover {
      display: block;
    }
  }
}
</style>
